@import "../config"
@import "/styles/config"

.new-progress
    background: #F6F8F9
    padding: 60px 0 80px
    margin: 0 0 120px 0
    +max-width($lg-tablet)
        padding: 32px 0 40px
        margin: 0 0 60px 0


    $root: &

    &__title
        font: 700 48px/120% $font
        margin-bottom: 40px
        +max-width($lg-tablet)
            font: 700 36px/120% $font
            margin-bottom: 24px
        +max-width($mobail)
            font: 700 24px/140% $font


    &__subtitle
        font: 600 30px/150% $font
        margin-bottom: 20px
        +max-width($lg-tablet)
            font: 600 28px/150% $font
            margin-bottom: 12px
        +max-width($mobail)
            font: 600 20px/140%


    &__table
        display: flex
        flex-direction: column
        //gap: 60px
        +max-width($lg-tablet)
    //gap: 40px


    &__list
        display: flex
        flex-direction: column
        padding: 0 0 60px 0
        +max-width($mobail)
            padding: 0 0 40px 0

        &:last-child
            padding: 0

    &__ratings
        display: flex
        flex-direction: column
        border-bottom: 1px solid #BDC9DE

    &__item
        display: flex
        border-top: 1px solid #BDC9DE
        padding: 32px 0
        width: 100%
        +max-width($lg-tablet)
            flex-wrap: wrap
            row-gap: 4px
            column-gap: 0
            padding: 12px 0



    &__text
        display: flex
        align-items: flex-start
        width: 200px
        font: 600 22px/150% $font
        +max-width($lg-tablet)
            font: 400 16px/150% $font
            align-items: center
            order: 3
            width: fit-content
        +max-width($mobail)
            font: 400 12px/125% $font


    &__description
        font: 400 22px/150% $font
        width: 90%
        padding-left: 40px
        display: flex
        column-gap: 13px
        align-items: flex-start
        transition: all 0.3s ease
        +max-width($desktop)
            padding-left: 20px
        +max-width($lg-tablet)
            order: 1
            width: 95%
            padding-left: 8px
            display: flex
            align-items: center
        +max-width($mobail)
            font: 400 14px/140% $font
            width: 85%

        &-text
            width: inherit
            color: $color
            transition: all 0.3s ease
            +max-width($lg-tablet)
                width: fit-content

        &-link
            margin-top: 8px
            display: none
            transition: all 0.3s ease
            +max-width($lg-tablet)
                display: none

        &:hover
            color: $hover
            #{$root}__description-link
                display: flex
                +max-width($lg-tablet)
                    display: none

            #{$root}__description-text
                color: $hover


    &__position
        min-width: 32px
        text-align: center
        font: 600 22px/150% $font
        color: $main-color
        +max-width($lg-tablet)
            font: 700 28px/140%  $font
            order: 0
        +max-width($mobail)
            font: 700 20px/140% $font

    &__year
        font: 600 22px/150% $font
        width: 60px
        +max-width($lg-tablet)
            font: 400 16px/150% $font
            color: #8F939C
            order: 2
            padding-left: 40px
            padding-right: 8px
            width: fit-content
        +max-width($mobail)
            font: 400 12px/125% $font
            display: flex
            align-items: center
